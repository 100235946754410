// TiiAdFactory
window.tgxToKarma.slots = (function(doc, win) {
    const karmaSlots = {},
        karmaTiersByType = {},
        c = win.karma.config,
        slotSizeMap = {
            '728x90': 'leaderboard',
            '970x90': 'leaderboard',
            '970x250': 'leaderboard',
            '900x500': 'leaderboard',
            '300x250': 'square',
            '300x600': 'square',
            '1050x600': 'square',
            '300x50': 'banner',
            '320x50': 'banner',
            '1x6': 'related'
        },
        hostname = win.location.hostname.split('.').slice(-2)[0],
        siteConfigs = {
            'departures': {
                injectMobileAdhesive: false,
                stickyClasses: []
            }
        },
        shouldInjectMobileAdhesive = siteConfigs[hostname] && siteConfigs[hostname].injectMobileAdhesive || true,
        stickyIds = (siteConfigs[hostname] && siteConfigs[hostname].stickyIds) ? siteConfigs[hostname].stickyIds : ['ad-footer-sticky', 'tethered-ad', 'univ-adhesion-ad', 'ad-mobile-adhesion', 'ad_resp_leaderboard', 'mobile-adhesive-ad', 'ad-adhesion-320x50_300x50'],
        stickyClasses = (siteConfigs[hostname] && siteConfigs[hostname].stickyClasses) ? siteConfigs[hostname].stickyClasses : ['sticky-ad', 'anchor-ad'];

    function karmaIndexByType(slotType) {
        return karmaSlots[slotType].length;
    }

    function incrementTier(slotType) {
        karmaTiersByType[slotType]++;
        if (karmaTiersByType[slotType] > 4) {
            karmaTiersByType[slotType] = 1;
        }
        return karmaTiersByType[slotType];
    }

    function getSlotSettings(tgxPlaceholder) {
        let slotConfig;
        if (win.Drupal && win.Drupal.settings) {
            slotConfig = win.Drupal.settings[tgxPlaceholder.id];
            slotConfig.sizes = Object.keys(slotConfig.sizes[c.isMobile ? 0 : 1000]);
        }
        return slotConfig;
    }

    function writeAd(id) {
        log('this.size: ' + this.size);
        const slot = {size: this.size};
        let placeholder;
        if (id) {
            slot.id = id;
        } else {
            const scripts = document.getElementsByTagName('script');
            placeholder = scripts[scripts.length - 1].parentNode;
        }
        insertKarmaPlaceholder(slot, placeholder);
    }

    function getAd(width, height) {
        return {
            size: [width, height],
            write: writeAd,
            setParam: foo,
            setPosition: foo
        };
    }

    function getMultiAd(sizeArray) {
        return {
            size: sizeArray,
            write: writeAd,
            setParam: foo,
            setPosition: foo
        };
    }

    function isNumeric(val) {
        return /^\d+$/.test(val);
    }

    function createSlot(slotConfig, tgxPlaceholder) {
        // log('createSlot called: slotConfig: '+(slotConfig !== false) + (tgxPlaceholder ? ', tgxPlaceholder: '+tgxPlaceholder.id : ''));
        const slot = {
                tgxId: slotConfig && slotConfig.id || tgxPlaceholder.id,
                type: null
            },
            validSizes = Object.keys(slotSizeMap);

        slot.tgxPlaceholder = tgxPlaceholder ? tgxPlaceholder : slot.tgxId ? document.getElementById(slot.tgxId) : null;
        slot.tgxClasses = slot.tgxPlaceholder && slot.tgxPlaceholder.className || null;
        slot.tgxPosition = slot.tgxPlaceholder && (slot.tgxPlaceholder.style.position || getComputedStyle(slot.tgxPlaceholder).position) || null;

        // get slotSettings
        if (!slotConfig) {
            slotConfig = getSlotSettings(tgxPlaceholder);
        } else {
            slotConfig.sizes = slotConfig.size;
            if (typeof slotConfig.size[0] === 'object' && Array.isArray(slotConfig.size[0]) && isNumeric(slotConfig.size[0][0])) {
                slotConfig.sizes = slotConfig.size.map(function(v) {
                    return v.join('x');
                });
            } else if (isNumeric(slotConfig.size[0])) {
                slotConfig.sizes = [slotConfig.size.join('x')];
            }
        }

        // filter out invalid sizes
        slotConfig.sizes = slotConfig.sizes.filter(function(size) {
            return validSizes.indexOf(size) !== -1;
        });
        log('slotConfig: ' + JSON.stringify(slotConfig));

        // get sizes
        slot.sizes = slotConfig.sizes;

        // get slot type by size
        if (slotConfig.type) {
            slot.type = slotConfig.type;
        } else {
            for (let i = 0, l = slot.sizes.length; i < l; i++) {
                if (slotSizeMap.hasOwnProperty(slot.sizes[i])) {
                    slot.type = slotSizeMap[slot.sizes[i]];
                    break;
                }
            }
        }

        // auto-detect mobile adhesive banner
        if (c.isMobile) {
            const hasStickyId = stickyIds.length > 0 && slot.tgxId && slot.tgxId.match(new RegExp(stickyIds.join('|'))),
                hasStickyClass = stickyClasses.length > 0 && slot.tgxClasses && slot.tgxClasses.match(new RegExp(stickyClasses.join('|')));
            let hasFixedPosition = slot.tgxPosition && slot.tgxPosition === 'fixed';
            // look up to 5 of the slot's ancestors to see if any of them are position: fixed
            if (!hasFixedPosition && slot.tgxPlaceholder) {
                let el = slot.tgxPlaceholder,
                    parentsToCheck = 3;
                while (parentsToCheck--) {
                    if (getComputedStyle(el).position === 'fixed') {
                        hasFixedPosition = true;
                    }
                    el = el.parentElement || el.parentNode;
                }
            }

            if ((hasStickyId || hasStickyClass || hasFixedPosition) && !karmaSlots['adhesive-banner']) {
                slot.type = 'adhesive-banner';
            }
        }

        if (karmaSlots[slot.type] && karmaSlots[slot.type].indexOf(slot.tgxId) !== -1) {
            return false;
        }

        // build id
        slot.sizeType = (slot.type === 'adhesive-banner' || slot.sizes.length === 1 && slot.sizes[0] !== '900x500') ? '-fixed' : '-flex';
        slot.id = 'div-gpt-' + (c.isMobile ? 'mob-' : '') + slot.type + ((slot.type !== 'related') ? slot.sizeType : '');

        // set incrementer and tier
        karmaSlots[slot.type] = karmaSlots[slot.type] || [];
        karmaTiersByType[slot.type] = karmaTiersByType[slot.type] || 0;
        karmaSlots[slot.type].push(slot.tgxId + '->' + slot.id);

        slot.id += '-' + karmaIndexByType(slot.type);
        slot.tier = incrementTier(slot.type);

        return slot;
    }

    function createKarmaPlaceholder(slotConfig, tgxPlaceholder) {
        const slot = createSlot(slotConfig, tgxPlaceholder),
            karmaDiv = (!slot || slot.type === null) ? false : doc.createElement('div');

        if (karmaDiv) {
            karmaDiv.id = slot.id;
            karmaDiv.setAttribute('data-tier', slot.tier);
            karmaDiv.setAttribute('data-tgx-id', slot.tgxId);
            log('new KARMA slot: ' + slot.id + ':tier' + slot.tier);
        }

        return karmaDiv;
    }

    function insertKarmaPlaceholder(slotConfig, tgxDiv) {
        // todo: skip creation if it already exists
        const karmaDiv = createKarmaPlaceholder(slotConfig, tgxDiv),
            relocateSelector = (tgxDiv && tgxDiv.dataset) ? tgxDiv.dataset.relocateSelector : null;
        let whereToInsert = tgxDiv || (slotConfig ? doc.getElementById(slotConfig.id) : doc.getElementById(karmaDiv.dataset.tgxId));

        // move div if 'data-relocate-selector'
        if (relocateSelector) {
            whereToInsert = doc.querySelector(relocateSelector);
        }

        if (whereToInsert === 'null' || karmaDiv === false) {
            return false;
        }

        const insertType = (tgxDiv && tgxDiv.dataset) ? tgxDiv.dataset.relocatePosition : 'append';

        // if KARMA is initialized
        if (win.karma.vars.initialized && win.karma.queueExecuted === undefined || win.karma.vars.queueExecuted) {
            // create a lazy-load slot
            karma.cmd.push(function() {
                window.karma.createSlot(karmaDiv.id.replace(/div-gpt-(.*)-\d+/, 'div-gpt-lazy-$1') + '-tier' + karmaDiv.dataset.tier, whereToInsert, true);
            });
            return false;
        }

        // append if that's the desired intention
        if (insertType === 'append') {
            return whereToInsert.appendChild(karmaDiv);
        }

        // otherwise insert next to the TGX div
        return whereToInsert.parentNode.insertBefore(karmaDiv, tgxDiv);
    }

    function injectMobileAdhesive() {
        log('injecting mobile adhesive container...');
        const adhesiveBannerContainer = doc.createElement('div');
        adhesiveBannerContainer.id = 'mobileHoverBannerWrapper';
        adhesiveBannerContainer.dataset.relocatePosition = 'append';
        // append styles
        const styleSheet = doc.createElement('style');
        styleSheet.id = 'dockingStyles';
        styleSheet.innerHTML = '#mobileHoverBannerWrapper{ position:fixed; bottom:0; width:320px; left:50%; margin-left:-160px; z-index:450;}';
        doc.getElementsByTagName('head')[0].appendChild(styleSheet);
        // insert adhesive banner container
        doc.body.appendChild(adhesiveBannerContainer);
        // insert adhesive banner placeholder
        return insertKarmaPlaceholder({size: [320, 50], type: 'adhesive-banner'}, adhesiveBannerContainer);
    }

    function init(tgxConfig) {
        log('init of TGX to KARMA translator');

        // if the hostname is on the list of deactivated sites, don't call KARMA
        if (win.tgxToKarma.vars.deactivatedHostnames.indexOf(win.tgxToKarma.vars.hostname) !== -1) {
            log('aborting the call to KARMA because the site ' + win.tgxToKarma.vars.hostname + ' is disabled');
            return false;
        }

        // init the config
        win.tgxToKarma.config.init(tgxConfig);
        win.tgxToKarma.vars.initialized = true;

        // commenting out for now because it's not needed on Departures and timing is funny
        // inject mobile adhesive banner
        if (shouldInjectMobileAdhesive) {
            win.karma.cmd.push(function() {
                if (window.karma.config.isMobile && Object.keys(karmaSlots).indexOf('adhesive-banner') === -1) {
                    injectMobileAdhesive();
                }
            });
        }

        const script = document.createElement('script');
        script.src = 'https://karma.mdpcdn.com/service/js-min/karma.js';
        document.getElementsByTagName('head')[0].appendChild(script);

    }

    function refreshAds() {
        log('manual refresh is not currently supported in this version of the TGX->KARMA translator', true);
    }

    function slotRenderEnded(fn) {
        window.karma.config.callback = window.karma.config.callback || [];
        window.karma.config.callback.push(fn);
    }

    function writeFoo() {
        return {write: foo};
    }

    const methods = {
            init: init,
            defineSlot: insertKarmaPlaceholder,
            catalog: karmaSlots,
            clearRefreshAds: foo,
            clearRefreshBatchAds: foo,
            clearParam: foo,
            createAd: insertKarmaPlaceholder,
            tgx_div: insertKarmaPlaceholder,
            getAd: getAd,
            getCmAd: writeFoo,
            getMultiAd: getMultiAd,
            getMultiCmAd: writeFoo,
            getTransitionalAd: writeFoo,
            loadOop: foo,
            params: c.targeting,
            refreshAds: refreshAds,
            refreshBatchAds: refreshAds,
            resetOOP: foo,
            setChannelPage: foo,
            setLazyLoad: foo,
            slotRenderEnded: slotRenderEnded
        },
        configMethods = ['setArticleId', 'setPackageId', 'setChannel', 'setSubchannel', 'setContentPage', 'setContentType', 'setZone', 'setParam'];
    let l = configMethods.length;
    while (l--) {
        const method = configMethods[l];
        methods[method] = win.tgxToKarma.config[method];
    }

    return methods;

}(document, window));