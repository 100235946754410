// TiiAdConfig
window.tgxToKarma.config = (function(doc, win) {
    const c = win.karma.config,
        userAgent = win.navigator.userAgent,
        pathName = win.location.pathname,
        pageMap = {
            '2015 Destination Guide': 'category',
            'app': 'app',
            'author': 'profile',
            'blog': 'post',
            'blogs': 'post',
            'business_listing_landing_page': 'landing_page',
            'buying_guides': 'buyingguides',
            'buyingguides': 'reviews',
            'calendar': 'slideshow',
            'campaign': 'page',
            'condition_center': 'app',
            'content': 'page',
            'contentcollection': 'collection',
            'custom': 'page',
            'franchise': 'page',
            'gallery': 'slideshow',
            'generic': 'page',
            'genericpage': 'page',
            'hgallery': 'slideshow',
            'image': 'photos',
            'list': 'checklist',
            'listicle': 'article',
            'magazine': 'page',
            'main': 'category',
            'menu': 'recipe_hub',
            'multitag': 'tag',
            'package': 'category',
            'playlist': 'landing_page',
            'poy': 'article',
            'recap': 'article',
            'section': 'category',
            'sectionfront': 'category',
            'sl50': 'landing_page',
            'storied': 'app',
            'subchannel': 'category',
            'syndicatedslideshow': 'slideshow',
            'toc': 'landing_page',
            'topic': 'category',
            'topicpage': 'category',
            'topicpagechild': 'category',
            'tout': 'page',
            'tvrecapsubchannel': 'category',
            'vertical_gallery': 'slideshow',
            'verticalgallery': 'slideshow',
            'videoarchive': 'video_hub',
            'videoarticle': 'video_article',
            'videolandingpage': 'video_hub',
            'videos': 'video_hub',
            'videoseries': 'video_hub'
        };

    // set required KARMA params
    c.apiVersion = 3;
    c.isMobile = !!userAgent.match(/Mobi/) && !userAgent.match(/iPad/);
    c.targeting.plat = c.isMobile ? 'mobile' : 'desktop';
    c.go = function() {
        window.karma.cmd.push('go');
    };

    function existsAndNotEmpty(str) {
        return str && str !== '';
    }

    function getCookie(sKey) {
        if (!sKey) {
            return null;
        }
        const cookie = doc.cookie;
        return decodeURIComponent(cookie.replace(new RegExp('(?:(?:^|.*;)\\s*' + encodeURIComponent(sKey).replace(/[-.+*]/g, '\\$&') + '\\s*\\=\\s*([^;]*).*$)|^.*$'), '$1')) || null;
    }

    function convertConfig(manualConfig) {
        // log('config init called');

        const keyMap = {
                'content_id': 'aid',
                'site_section1': 'ch',
                'content_package_id': 'pid',
                'site_section2': 'sch',
                'pi_page_type': 'ptype',
                'template_type': 'ctype'
            },
            tgxScriptNode = doc.getElementById('tgx-script'),
            tgxTargeting = {};
        let tempObj = {};


        /* commented out for now because Departures has site-run docking
    // look at .sticky-ad to configure leaderboard docking automatically
    c.docking.leaderboard = c.docking.leaderboard || {};
    c.docking.leaderboard.dockingElementSelector = '#ad-header-top.sticky-ad-processed';
    c.docking.leaderboard.belowBannerSelector = 'header > .pane-sitewide-header ~ .pane-page-breadcrumb';
    c.docking.leaderboard.siteContainerSelector = '.main-container';
    */

        if (manualConfig) {
            if (manualConfig.setGlobalTargeting) {
                Object.keys(manualConfig.setGlobalTargeting).forEach(function(key) {
                    tempObj[key] = manualConfig.setGlobalTargeting[key];
                });
            }
            if (manualConfig.setTargeting) {
                Object.keys(manualConfig.setTargeting).forEach(function(key) {
                    tempObj[key] = manualConfig.setTargeting[key];
                });
            }
        } else {

            // convert #tgx-script
            if (tgxScriptNode && tgxScriptNode.dataset.tgxtargeting) {
                tempObj = JSON.parse(decodeURIComponent(tgxScriptNode.dataset.tgxtargeting));
            }

            // convert Ti.udo_metadata
            if (win.Ti && win.Ti.udo_metadata) {
                log('win.Ti.udo_metadata:' + JSON.stringify(win.Ti.udo_metadata));
                tempObj = extend(tempObj, win.Ti.udo_metadata);
            }

            // convert ti_dfp_config
            if (win.ti_dfp_config && win.ti_dfp_config.setGlobalTargeting) {
                log('win.ti_dfp_config:' + JSON.stringify(win.ti_dfp_config));
                c.tgxSiteName = win.ti_dfp_config.setTargeting.site;
                tempObj = extend(tempObj, win.ti_dfp_config.setGlobalTargeting);
            }

            // convert Drupal.settings.ad_settings.adFactory
            if (win.Drupal && win.Drupal.settings.ad_settings && win.Drupal.settings.ad_settings.adFactory) {
                log('win.Drupal.settings.ad_settings.adFactory:' + JSON.stringify(win.Drupal.settings.ad_settings.adFactory));
                Object.keys(win.Drupal.settings.ad_settings.adFactory).forEach(function(key) {
                    if (typeof win.tgxToKarma.config[key] === 'function') {
                        win.tgxToKarma.config[key](win.Drupal.settings.ad_settings.adFactory[key]);
                    }
                });
            }

        }
        log('tempObj:' + JSON.stringify(tempObj));
        // loop through tempObj and change keys as needed
        if (tempObj) {
            Object.keys(tempObj).forEach(function(k) {
                tgxTargeting[keyMap[k] || k] = tempObj[k];
                c.targeting[keyMap[k] || k] = tempObj[k];
            });
        }

        // id
        setId(tgxTargeting);

        // path
        c.targeting.path = pathName.split('/').filter(function(v) {
            return v !== '';
        });

        // channel/parent
        if (existsAndNotEmpty(tgxTargeting.ch)) {
            c.targeting.channel = tgxTargeting.ch;
        }
        if (existsAndNotEmpty(tgxTargeting.sch)) {
            c.targeting.parent = tgxTargeting.sch;
        }

        c.targeting.domain = win.location.hostname;
        const tiId = getCookie('globalTI_SID');
        if (tiId) {
            c.targeting.ti_id = tiId;
        }

        // type
        setType(tgxTargeting);

        const aamId = getCookie('aam_uuid');
        if (aamId) {
            c.targeting.aamid = aamId;
        }

        log('win.karma.config:' + JSON.stringify(win.karma.config));

    }

    function setId(targeting) {
        if (existsAndNotEmpty(targeting.id)) {
            c.targeting.id = targeting.id;
        } else if (existsAndNotEmpty(targeting.aid)) {
            c.targeting.id = targeting.aid;
        } else if (existsAndNotEmpty(targeting.cid)) {
            c.targeting.id = targeting.cid;
        } else if (existsAndNotEmpty(targeting.pid)) {
            c.targeting.id = targeting.pid;
        } else if (existsAndNotEmpty(targeting.articleID)) {
            c.targeting.id = targeting.articleID;
        }
        // default id from path if none given
        if (!c.targeting.id || c.targeting.id === '') {
            c.targeting.id = pathName.substr(1, (pathName.length < 40 ? pathName.length : 40)).replace(/\//g, '_').replace(/[^a-z0-9-]/gi, '');
        }
        return c.targeting.id;
    }

    function mapType(type) {
        return pageMap.hasOwnProperty(type) ? pageMap[type] : type;
    }

    function setType(targeting) {
        if (existsAndNotEmpty(targeting.ctype)) {
            c.targeting.type = mapType(targeting.ctype);
        } else if (existsAndNotEmpty(targeting.ptype)) {
            c.targeting.type = mapType(targeting.ptype);
        }
        return c.targeting.type;
    }

    function setParam(key, value) {
        // recursive functionality if an object is passed in
        if (typeof key === 'object') {
            Object.keys(key).forEach(function(k) {
                if (!k.match(/id|targeting|size|sizes/)) {
                    setParam(k, key[k]);
                }
            });
            return false;
        }

        if (value === undefined) {
            return false;
        }

        // if the key already exists, convert it to an array and push in the new value
        if (c.targeting.hasOwnProperty(key) && c.targeting[key] !== value) {
            if (typeof c.targeting[key] !== 'object') {
                c.targeting[key] = [c.targeting[key]];
            }
            if (Array.isArray(c.targeting[key])) {
                c.targeting[key].push(value);
            }
        }
        // if the key is comma-separated, convert it to an array, otherwise, just pass the value through
        c.targeting[key] = (typeof value === 'string' && value.indexOf(',') !== -1) ? value.split(',') : value;
        if (key.match(/id$/)) {
            setId(c.targeting);
        } else if (key.match(/type$/)) {
            setType(c.targeting);
        }

        // if KARMA has already been initialized, update the targeting
        if (win.karma.vars.initialized) {
            if (typeof win.karma.updateTargeting === 'function') {
                window.karma.updateTargeting(c.targeting);
            } else {
                win.karma.cmd.push(function() {
                    window.karma.updateTargeting(c.targeting);
                });
            }
        }
        return value;
    }

    function setArticleId(v) {
        setParam('aid', v);
        setId(c.targeting);
    }

    function setPackageId(v) {
        setParam('pid', v);
        setId(c.targeting);
    }

    function setChannel(v) {
        setParam('ch', v);
        setParam('channel', v);
    }

    function setSubchannel(v) {
        setParam('sch', v);
        setParam('parent', v);
    }

    function setContentType(v) {
        setParam('ctype', v);
        setType(c.targeting);
    }

    function setZone(k, v) {
        setParam('zone', v);
    }

    function setSitename(v) {
        let site = v.split('.').pop();
        const map = {
            'mre2': 'mre',
            'hlt2': 'hlt',
            'fort_mob': 'fort'
        };
        if (map[site]) {
            site = map[site];
        }
        c.site = site + '.mdp.' + (c.isMobile ? 'mob' : 'com');
    }

    return {
        init: convertConfig,
        setParam: setParam,
        setSitename: setSitename,
        setCmSitename: foo,
        filterSitename: foo,
        setBehaviorTracking: foo,
        setTacodaTracking: foo,
        setQuantcastTracking: foo,
        setdisableInitLoad: foo,
        setsingleRequestMode: foo,
        setDequeueAds: foo,
        setConvertHyphens: foo,
        setStripNonAlphaNumeric: foo,
        setLazyLoad: foo,
        setLazyRange: foo,
        setPopups: foo,
        setRevSciTracking: foo,
        setChannelPage: foo,
        setArticleId: setArticleId,
        setPackageId: setPackageId,
        setChannel: setChannel,
        setSubchannel: setSubchannel,
        setContentPage: foo,
        setContentType: setContentType,
        setZone: setZone
    };

}(document, window));