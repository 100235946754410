window.tgxToKarma.vars.ready = true;

if (typeof window.time_dfp === 'object' && Array.isArray(window.time_dfp)) {
    window.tgxToKarma.queue = window.tgxToKarma.queue.concat(window.time_dfp.filter(function(fn) {
        return typeof fn === 'function';
    }));
}

// TEST: if we're using the TGX translator, window.time_dfp is a globally scoped object
window.time_dfp = (function() {
    const config = {
        setTargeting: {},
        setGlobalTargeting: {},
        enableSingleRequest: true,
        disableInitialLoad: true,
        collapseEmptyDivs: true,
        sitename: '',
        comscore: false,
        oop: false,
        zone: '',
        gptAsync: false
    };

    function refresh() {
    // disabled due to excessive abuse of refresh on Departures
    // window.karma.cmd.push(window.karma.refresh);
        log('manual refresh is not currently supported in this version of the TGX->KARMA translator', true);
    }

    function push(func) {
        if (typeof func === 'function') {
            try {
                func();
            } catch (e) {
                log('error running function: ' + e, true);
            }
        }
    }

    // TEST: if we're using the TGX translator, window.time_dfp has the following function methods: dfpUtil, init, defineSlot, refresh, updateCorrelator, setTargeting, and push
    return {
        dfpUtil: foo,
        config: config,
        init: window.tgxToKarma.slots.init,
        defineSlot: window.tgxToKarma.slots.defineSlot,
        refresh: refresh,
        updateCorrelator: foo,
        setTargeting: window.tgxToKarma.config.setParam,
        push: push
    };

}());

while (window.tgxToKarma.queue.length > 0) {
    try {
        window.tgxToKarma.queue[0]();
    } catch (e) {
        log('error running function: ' + e, true);
    }
    window.tgxToKarma.queue.splice(0, 1);
}

window.TiiAdConfig = function(sitename) {
    if (sitename) {
        window.tgxToKarma.config.setSitename(sitename);
    }
    return window.tgxToKarma.config;
};
window.TiiAdFactory = function(config) {
    window.tgxToKarma.slots.init(config);
    return window.tgxToKarma.slots;
};

function TgxData() {
    this.params = {};
    this.bkparams = [];
    this.pageparams = [];
    this.slotparams = [];
    this.refreshSlot = null;
    this.istSlot = null;
    this.istCmSlot = null;
    this.parentDiv = null;
    this.zone = '';
    this.slotQueue = [];
    this.renderingMode = null;
    this.processMode = null;
    this.disableinitMode = null;
    this.singlerequestMode = null;
    this.slots = [];
    this.slotkeys = [];
    this.oopslots = [];
}

window.tgxDo = new TgxData();

const functionList = [
    'TimeHatConfig',
    'TiiAdGetQuantcastSegments',
    'TiiAdTrackRevSci',
    'TiiAdQuantBlueKaiMindsetImpl',
    'TiiAdFactorySetbkParam',
    '_TiiAudsciTargeting',
    'TiiAdTrackBehavior',
    'TiiAdGetTacodaSegments',
    'TiiAdGetRevSciSegments',
    'TiiAdsGetVideoTestParam',
    'tiiAdSetType',
    'tiiAdSetTarget',
    'tiiHtmlAdWH',
    'TiiBkBeacon',
    'TiiBkFireBeacon',
    'TgxUtilGetRootDomain',
    'TgxUtilGetSubDomain'
];

for (let i = 0; i < functionList.length; i++) {
    window[functionList[i]] = foo;
}