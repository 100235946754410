/* eslint-disable no-unused-vars */
window.tgxToKarma = window.tgxToKarma || {
    vars: {
        debug: false,
        environment: null,
        initialized: false,
        ready: false,
        reloaded: false,
        scriptPath: '',
        hostname: window.location.hostname.split('.').splice(-2)[0],
        isTranslatedFromTgx: true,
        deactivatedHostnames: ['departures']
    },
    queue: []
};

window.karma = window.karma || {};
window.karma.cmd = window.karma.cmd || [];
window.karma.config = window.karma.config || {targeting: {}, docking: {}};
window.karma.vars = window.karma.vars || {};
window.karma.vars.isTranslatedFromTgx = true;

window.googletag = window.googletag || {};
window.googletag.cmd = window.googletag.cmd || [];

function foo() {}

function log(msg, force) {
    if (force || window.tgxToKarma.vars.debug || window.location.search.indexOf('tgxToKarmaDebug=true') !== -1) {
        console.log('%c ' + 'TGX->KARMA: ' + msg, 'background: #222; color: #bada55');
    }
}

function extend(obj1, obj2) {
    if (obj2) {
        Object.keys(obj2).forEach(function(p) {
            try {
                // Property in destination object set; update its value.
                if (obj2[p].constructor === Object) {
                    obj1[p] = extend(obj1[p], obj2[p]);
                } else {
                    obj1[p] = obj2[p];
                }
            } catch (e) {
                // Property in destination object not set; create it and set its value.
                obj1[p] = obj2[p];
            }
        });
    }
    return obj1;
}